(<template>
  <main
    class="public-profile">
    <header class="public-profile__header">
      <router-link :to="$makeRoute({name: 'Landing'})"
                   class="public-profile__logo" />
      <nav v-show="showNav"
           class="public-profile__nav-cont">
        <a :href="externalLoginPageLink"
           class="sk-btn sk-btn--white public-profile__btn-link">{{ $gettext('Log in') }}</a>
        <router-link :to="$makeRoute({name: 'SignUp'})"
                     class="sk-btn sk-btn--default public-profile__btn-link">{{ $gettext('Join now') }}</router-link>
        <locale-select class="public-profile__locale-select" />
      </nav>
      <button :class="{'is-opened-mobile-nav': mobileNavState}"
              class="public-profile__burger-btn"
              @click="toggleMobileNav">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </button>
    </header>
    <div class="public-profile__content-wrapper">
      <profile-page />
    </div>
  </main>
</template>)

<script>
  import {mapState, mapGetters} from 'vuex';
  import constants from '@/modules/constants';
  import ProfilePage from '@/pages/shared_pages/profile/sh_ProfileShow';

  export default {
    asyncData({store, route}) {
      const {int_country: country, slug} = route.params;
      const page = +route.query.page || 1;
      const items = +route.query.items || 5;
      const promiseArr = [];
      if (!store.state.selectsData.language || !store.state.selectsData.language.length) {
        promiseArr.push(store.dispatch('fetchSelectList', 'language'));
      }
      if (!store.state.selectsData.qualification || !store.state.selectsData.qualification.length) {
        promiseArr.push(store.dispatch('fetchSelectList', 'qualification'));
      }
      if (!store.state.EditProfileStore.profileData) {
        promiseArr.push(store.dispatch('EditProfileStore/getUserProfile', {country, slug}));
      }
      if (!store.state.EditProfileStore.publicFeedbacks) {
        promiseArr.push(store.dispatch('EditProfileStore/getInterpreterFeedback', {params: {page, items}, country, slug}));
      }
      return Promise.all(promiseArr);
    },
    components: {
      'profile-page': ProfilePage
    },
    metaInfo() {
      const faviconPath = this.$getString('metaData', 'faviconPath');
      return {
        title: this.computedTitle,
        meta: [
          {vmid: 'description', name: 'description', content: this.computedDescription},
          {vmid: 'keywords', name: 'keywords', content: this.keywords},
          {vmid: 'og:description', name: 'og:description', content: this.computedDescription},
          {vmid: 'og:url', name: 'og:url', content: this.computedUrl},
          {vmid: 'og:title', name: 'og:title', content: this.computedTitle},
          {vmid: 'og:image', name: 'og:image', content: this.avatar},
          {vmid: 'profile:first_name', name: 'profile:first_name', content: this.firstName},
          {vmid: 'profile:last_name', name: 'profile:last_name', content: this.lastName},
          {vmid: 'fb:app_id', name: 'fb:app_id', content: '247801502218267'},
          {vmid: 'og:type', name: 'og:type', content: 'profile'}
        ],
        link: [
          {rel: 'apple-touch-icon', sizes: '57x57', href: `${faviconPath}/apple-icon-57x57.png`},
          {rel: 'apple-touch-icon', sizes: '60x60', href: `${faviconPath}/apple-icon-60x60.png`},
          {rel: 'apple-touch-icon', sizes: '72x72', href: `${faviconPath}/apple-icon-72x72.png`},
          {rel: 'apple-touch-icon', sizes: '76x76', href: `${faviconPath}/apple-icon-76x76.png`},
          {rel: 'apple-touch-icon', sizes: '114x114', href: `${faviconPath}/apple-icon-114x114.png`},
          {rel: 'apple-touch-icon', sizes: '120x120', href: `${faviconPath}/apple-icon-120x120.png`},
          {rel: 'apple-touch-icon', sizes: '144x144', href: `${faviconPath}/apple-icon-144x144.png`},
          {rel: 'apple-touch-icon', sizes: '152x152', href: `${faviconPath}/apple-icon-152x152.png`},
          {rel: 'apple-touch-icon', sizes: '180x180', href: `${faviconPath}/apple-icon-180x180.png`},
          {rel: 'icon', sizes: '192x192', href: `${faviconPath}/android-icon-192x192.png`},
          {rel: 'icon', sizes: '32x32', href: `${faviconPath}/favicon-32x32.png`},
          {rel: 'icon', sizes: '96x96', href: `${faviconPath}/favicon-96x96.png`},
          {rel: 'icon', sizes: '16x16', href: `${faviconPath}/favicon-16x16.png`},
          {rel: 'shortcut icon', type: 'image/ico', href: `${faviconPath}/favicon.ico`}
        ]
      };
    },
    data() {
      return {
        clientWidth: 768,
        mobileNavState: false,
        externalLoginPageLink: this.$getMarketplaceName() === 'salita' && constants.EXTERNAL_LOGIN_PAGE
          ? constants.EXTERNAL_LOGIN_PAGE
          : this.$router.resolve(this.$makeRoute({name: 'SignIn'})).href
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userIsInterpreter']),
      ...mapState('EditProfileStore', {
        profileData: (state) => state.profileData || {},
        profilePerson: (state) => {
          return state.profileData && state.profileData.person ? state.profileData.person : {};
        },
        profileAddress: (state) => {
          return state.profileData && state.profileData.address ? state.profileData.address : {};
        }
      }),
      showNav() { return this.clientWidth > 767 || this.mobileNavState; },
      // person information
      uid() { return this.profilePerson.uid || ''; },
      avatar() { return this.profilePerson.avatar || ''; },
      firstName() { return this.profilePerson.firstName || ''; },
      lastName() { return this.profilePerson.lastName || ''; },
      computedDescription() {
        const standardDescr = this.$getString('metaData', 'description');
        return this.profilePerson.about || standardDescr;
      },
      // profile data
      intTypes() { return this.profileData.sessionTypes || ''; },
      expertiseAreas() { return this.profileData.expertiseAreas || ''; },
      skills() { return this.profileData ? this.profileData.skills || [] : ''; },
      interpretationMethods() { return this.profileData.interpretationMethods || ''; },
      translationMethods() { return this.profileData?.translator?.translationMethods || ''; },
      // address information
      city() { return this.profileAddress.city || ''; },
      country() { return this.profileAddress.country || ''; },
      computedUrl() {
        const rootPath = this.$getString('rootPath', process.env.NODE_ENV);
        return `${rootPath}${this.$route.fullPath}`;
      },
      keywords() {
        const res = [];
        const role = this.$gettext('Interpreter');
        const fullName = this.firstName && this.lastName ? `${this.firstName} ${this.lastName}` : '';
        const {country, city} = this;
        const skillsString = this.getSkillsString();
        const areasString = this.getAllAreasString();
        const methodsString = this.getAllMethodsString();
        const typesString = this.getAllTypesString();

        if (fullName) res.push(fullName);
        res.push(role);
        if (country) res.push(country);
        if (city) res.push(city);
        if (skillsString) res.push(skillsString);
        if (areasString) res.push(areasString);
        if (methodsString) res.push(methodsString);
        if (typesString) res.push(typesString);

        return res.join(', ');
      },
      computedTitle() {
        const skillsString = this.getSkillsString();
        const fullName = this.firstName && this.lastName ? `${this.firstName} ${this.lastName}` : this.$gettext('Interpreter');
        const template = 'Hire %{name} to interpret between %{skills}';
        return this.$gettextInterpolate(template, {name: fullName, skills: skillsString});
      }
    },
    methods: {
      getSkillsString() {
        const allSkills = this.skills;
        let skillsString = '';
        if (allSkills && allSkills.length) {
          const skillsTempArr = [];
          let langFromId = '';
          let langToId = '';
          let langFromName = '';
          let langToName = '';
          for (const skill of allSkills) {
            if (skill.approved) {
              langFromId = skill.langFromId;
              langToId = skill.langToId;
              for (const lang of this.$store.state.selectsData.language) {
                if (lang.id == langFromId) {
                  langFromName = lang.name;
                }
                if (lang.id == langToId) {
                  langToName = lang.name;
                }
              }
              if (langFromName && langToName) {
                const skillsTemplate = '%{langFrom} to %{langTo}';
                skillsTempArr.push(this.$gettextInterpolate(skillsTemplate, {langFrom: langFromName, langTo: langToName}));
              }
            }
          }
          skillsString = skillsTempArr.join(', ');
        }
        return skillsString;
      },
      getAllAreasString() {
        const allAreas = this.expertiseAreas;
        let areasString = '';
        if (allAreas && allAreas.length) {
          const tempAreasArr = [];
          for (const area of allAreas) {
            if (area.checked) {
              tempAreasArr.push(area.name);
            }
          }
          areasString = tempAreasArr.join(', ');
        }
        return areasString;
      },
      getAllMethodsString() {
        const allMethods = this.interpretationMethods;
        let methodsString = '';
        if (allMethods && allMethods.length) {
          const tempMethodsArr = [];
          for (const method of allMethods) {
            if (method.checked) {
              tempMethodsArr.push(method.name);
            }
          }
          methodsString = tempMethodsArr.join(', ');
        }
        return methodsString;
      },
      getAllTypesString() {
        const allTypes = this.intTypes;
        let typesString = '';
        if (allTypes && allTypes.length) {
          const tempTypesArr = [];
          for (const type of allTypes) {
            if (type.checked) {
              switch (type.name) {
                case 'video':
                  tempTypesArr.push(this.$gettext('Video assignment'));
                  break;
                case 'phone':
                  tempTypesArr.push(this.$gettext('Phone assignment'));
                  break;
                case 'in_person':
                  tempTypesArr.push(this.$gettext('In-person assignment'));
                  break;
                case 'videoroom':
                  tempTypesArr.push(this.$gettext('Video room assignment'));
                  break;
              }
            }
          }
          typesString = tempTypesArr.join(', ');
        }
        return typesString;
      },
      recalculateWidth() {
        this.clientWidth = document.documentElement.clientWidth;
      },
      toggleMobileNav() {
        this.mobileNavState = !this.mobileNavState;
      }
    },
    mounted() {
      this.recalculateWidth();
      window.addEventListener('resize', this.recalculateWidth);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.recalculateWidth);
    }
  };
</script>

<style src="@assets/css/public_profile.css" scoped></style>
